/* Sign Up Page Styling */
.signup-container {
    background-color: #ffe6f2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .signup-container h2 {
    color: #ff66b2;
    margin-bottom: 20px;
  }
  
  .signup-container input[type="text"],
  .signup-container input[type="password"] {
    padding: 10px;
    width: calc(100% - 20px);
    border: 2px solid #ff66b2;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 1rem;
    outline: none;
  }
  
  .signup-container button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #ff66b2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .signup-container button:hover {
    background-color: #ff3385;
  }

