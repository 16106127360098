/* General App styling */
body {
    font-family: Arial, sans-serif;
    background: linear-gradient(to right, #ff66b2, #ff99cc);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  
  h2 {
    color: #fff;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* To-Do List Container */
  .todo-container {
    background-color: #ffe6f2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
  }
  
  input[type="text"] {
    padding: 10px;
    width: calc(100% - 100px);
    border: 2px solid #ff66b2;
    border-radius: 5px;
    font-size: 1rem;
    margin-right: 10px;
    outline: none;
  }
  
  button {
    padding: 10px;
    font-size: 1rem;
    background-color: #ff66b2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #ff3385;
  }
  
  /* To-Do List Items */
  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  li {
    background-color: #ffb3d9;
    color: #4a4a4a;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }
  
  li:hover {
    background-color: #ff99cc;
  }
  
  li span {
    cursor: pointer;
    font-size: 1.1rem;
  }
  
  /* Completed Item */
  li span.completed {
    text-decoration: line-through;
    color: #b30059;
  }
  
  /* Delete Button */
  button.delete-btn {
    background-color: #ff3385;
    font-size: 0.9rem;
    padding: 5px 10px;
  }
  
  button.delete-btn:hover {
    background-color: #cc004d;
  }
  